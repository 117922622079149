import * as React from 'react'

const SvgBreadcrumbArrow = ({className = 'fillpath-blue600'}) => (
  <svg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.84924 6.35502C5.94558 6.26204 6 6.13389 6 6V6C6 5.86611 5.94558 5.73796 5.84924 5.64498L0.27342 0.263871C0.170968 0.164997 5.14122e-07 0.237601 5.07903e-07 0.379982V0.379982C5.06783e-07 0.405624 0.0061117 0.430898 0.0178276 0.453707L2.64092 5.56059C2.78867 5.84825 2.7882 6.1896 2.63964 6.47685L0.0180247 11.5461C0.006181 11.569 1.77115e-08 11.5944 1.65856e-08 11.6202V11.6202C1.03709e-08 11.7625 0.17089 11.8351 0.273295 11.7362L5.84924 6.35502Z'
      className={className}
    />
  </svg>
)

export default SvgBreadcrumbArrow
