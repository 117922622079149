import * as React from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '@/../tailwind.config.js'
import Image from '@/components/Image'

const fullConfig = resolveConfig(tailwindConfig)

const HeroHashtag = ({title, imgLeft, imgRight}) => {
  return (
    <section className='bg-blue700 py-11 md:py-13 lg:py-19'>
      <div className='absolute top-1/2 left-0 z-0 w-[192px] md:top-1/4 md:w-[306px] lg:top-0 lg:w-[344px]'>
        {imgLeft && imgLeft.filename && (
          <Image
            src={imgLeft.filename}
            alt={imgLeft.alt}
            sizes={`(min-width: ${fullConfig.theme.screens.md}) 306px, (min-width: ${fullConfig.theme.screens.sm}) 192px, 100vw`}
            className='block w-full'
          />
        )}
      </div>
      <div className='absolute -top-1/2 right-0 z-0 w-[192px] md:w-[306px] lg:-top-1/4 lg:w-[344px]'>
        {imgRight && imgRight.filename && (
          <Image
            src={imgRight.filename}
            alt={imgRight.alt}
            sizes={`(min-width: ${fullConfig.theme.screens.md}) 306px, (min-width: ${fullConfig.theme.screens.sm}) 192px, 100vw`}
            className='block w-full'
          />
        )}
      </div>
      <div className='pelo-grid'>
        <div className='col-span-full text-center'>
          {title ? <h1 className='heading-m text-grey000'>{`#${title}`}</h1> : null}
        </div>
      </div>
    </section>
  )
}

export default HeroHashtag
