import classNames from 'classnames'
import React from 'react'

import {utils} from '@/common/config'
import Breadcrumb from '@/components/Blog/Breadcrumb/Breadcrumb'
import HeroHashtag from '@/components/Blog/HeroHashtag/HeroHashtag'
import HeadComponent from '@/components/Head'
import RessourcesCard from '@/components/RessourcesCard/RessourcesCard'
import Layout from '@/templates/Layout'

const BlogList = (data) => {
  let story, posts, field_component

  if (data.pageContext) {
    story = data.pageContext
    posts = data.pageContext.posts
    field_component = data.pageContext.field_component
  } else {
    story = data.content
    posts = data.posts
    field_component = data.content.component
  }

  if (!story || !posts) {
    return null
  }

  let bgColor = 'white'
  if (
    story.layout &&
    story.layout.content &&
    typeof story.layout.content === 'object' &&
    story.layout.content.backgroundColor
  ) {
    bgColor = story.layout.content.backgroundColor
  }

  return (
    <Layout backgroundColor={bgColor} {...story.layout} posts={posts}>
      <main>
        {field_component === 'blog-tag' && (
          <HeroHashtag
            title={story.title}
            imgLeft={story.heroImageLeft}
            imgRight={story.heroImageRight}
          />
        )}
        <section className={classNames('pt-[100px] pb-[44px]', ['background-' + bgColor])}>
          {field_component === 'blog-category' && <Breadcrumb title={story.title} />}

          <div className='pelo-grid'>
            <div className='col-span-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
              {posts.map((post) => {
                if (!post.content || !post.content.category) return null
                const props = {
                  link: {url: utils.getPagePath(post.full_slug)},
                  image: post.content.cover,
                  type: post.content.category?.slug,
                  tags: post.content.tags?.map((tag) => ({...tag, label: tag.name})),
                  heading: post.content.heading,
                  description: post.content.intro,
                  category: post.content.category,
                }

                return (
                  <RessourcesCard key={post.uuid} {...props} field_component={field_component} />
                )
              })}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const Head = ({pageContext}) => <HeadComponent {...pageContext} />

export default BlogList
