import React from 'react'

import DynamicComponent from '@/components/DynamicComponent'

const Placeholder = () => (
  <div className='py-50 color-[#1b243f] bg-[#f7f8f9] px-2 text-center text-[2.6rem] font-bold uppercase'>
    Content 📝
  </div>
)

const Layout = ({backgroundColor, content, children, isPreview, posts}) => {
  const {top = [], bottom = []} = content || {}
  return (
    <>
      {top.map((component) => (
        <DynamicComponent
          key={component._uid}
          {...component}
          _editable={isPreview ? component._editable : undefined}
          heroBackgroundColor={backgroundColor}
          posts={posts}
        />
      ))}

      {isPreview ? <Placeholder /> : children}

      {bottom.map((component) => (
        <DynamicComponent
          key={component._uid}
          {...component}
          _editable={isPreview ? component._editable : undefined}
          heroBackgroundColor={backgroundColor}
          posts={posts}
        />
      ))}
    </>
  )
}

export default Layout
