import classNames from 'classnames'
import * as React from 'react'

import SvgBreadcrumbArrow from '@/assets/svgjsx/BreadcrumbArrow'
import {makeEditable} from '@/common/utils'
import Lien from '@/components/Lien'

const Breadcrumb = ({title, category, foregroundColor = 'blue400', className, _editable}) => {
  return (
    <div className={classNames('w-full py-6', className)}>
      <div {...makeEditable(_editable)} className='pelo-grid'>
        <div className='col-span-full flex items-center gap-2'>
          <Lien
            link={{cached_url: 'ressources', linktype: 'story'}}
            className={classNames('body-xs-regular', {
              [`textcolor-${foregroundColor}`]: foregroundColor,
            })}
          >
            Ressources
          </Lien>

          <SvgBreadcrumbArrow className={`fillpath-${foregroundColor}`} />
          {category && category.full_slug && (
            <>
              <Lien
                link={{cached_url: `${category.full_slug}`, linktype: 'story'}}
                className={classNames('body-xs-regular', {
                  [`textcolor-${foregroundColor}`]: foregroundColor,
                })}
              >
                {category.name}
              </Lien>
              <SvgBreadcrumbArrow className={`fillpath-${foregroundColor}`} />
            </>
          )}
          <p className='body-xs-regular max-w-[88px] truncate text-blue900'>{title}</p>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
